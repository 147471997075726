import React, { useState } from "react";
import { Section, Seo } from "@components";
import { OfferDialog } from "../../components/site/offer";
import { PageLayout } from "../../components/site/layout/page-layout";
import { PartnerGrid } from "../../components/site/product";

const ProductIntegrationsPage = ({ location }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const onCloseDialog = () => setIsDialogOpen(false);

  return (
    <>
      <PageLayout pathname={location.pathname}>
        <div className="flex flex-wrap justify-center content-center w-full  h-[48vw] md:min-h-[474px] p-[20px] min-h-[327px] max-h-[474px] bg-[#122331]">
          <div className="container w-full not-prose">
            <div classNames="h-max w-max not-prose pt-[20px]">
              <h1 className="text-[35px] md:text-[58px] font-black leading-[58px] tracking-tight mb-[0px] text-[#acafb3] ml-0 md:ml-[85px] md:text-left text-center">
                Technology Partner and Integration
              </h1>
              <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-[50%] md:w-[20%] m-auto md:ml-[155px] mt-[23px]" />
            </div>
          </div>
        </div>
        <Section>
          <div className="container not-prose">
            <div className="mt-10 mx-0 md:mx-5 !tracking-[-0.05em]">
              <p class="text-[#717272] text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose ">
                Protecting the APIs that serve your applications has never been more important. To make it easy to
                integrate Approov into your security environment, we work closely with other security vendors in order
                to ensure optimal protection and ease of deployment. We integrate and test Approov with the industry's
                best security vendors to help our customers protect their revenue, data and brand.
              </p>
            </div>
            <div className="md:mx-5 my-5">
              <p class="text-[24px] md:text-[41px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px] text-[#1b3d57]">
                Backend Integration
              </p>
              <p class="text-[#717272] text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose ">
                Approov integrates easily with any backend environment: Quickstart guides are already available for many
                commonly used environments. However, many customers seek to simplify security by having a single-point
                where their security policy is enforced in front of the API. Approov supports this through integrations
                with backend security platforms, adding the Approov assurance that APIs can only be used by genuine
                instances of your mobile app.
              </p>
            </div>
            <div className="md:mx-5">
              <PartnerGrid tag="backend" />
            </div>
            <hr className="w-full mb-[40px] border-[1px] bg-[#e2e2e2]" />
            <div className="my-5 md:mx-5">
              <p className="text-[24px] md:text-[41px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px] text-[#1b3d57]">
              Cloud Integration
              </p>
              <p class="text-[#717272] text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose ">
                Integration with cloud gateways allows the Approov mobile app and client environment attestation check
                to be enforced at the gateway to ensure full and consistent security for cloud-native APIs.
              </p>
            </div>
            <div className="md:mx-5">
              <PartnerGrid tag="cloud" className="w-full lg:w-2/3" />
            </div>
            <hr className="w-full mb-[40px] border-[1px] bg-[#e2e2e2]"/>
            <div className="my-5 md:mx-5">
              <p className="text-[24px] md:text-[41px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px] text-[#1b3d57]">
                Browser Protection
              </p>
              <p className="text-[#717272] text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose ">
              Some mobile-first customers allow browser-based access to the same APIs used by their mobile apps. To provide a single common validation method for mobile apps and browser-based access, Approov integrates with solutions which evaluate whether a browser access is by a human or a bot. This enables a single, common authorization method for both the web and mobile API channels in order to validate legitimate access.
              </p>
            </div>
            <div className="md:mx-5">
                <PartnerGrid tag="browser" />
            </div>
          </div>
        </Section>
      </PageLayout>

      <OfferDialog offer="partnership" open={isDialogOpen} onClose={onCloseDialog} />
    </>
  );
};

export default ProductIntegrationsPage;
